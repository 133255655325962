<template>
  <div class="home">
    <img class="background" src="../../assets/image/background.png" alt srcset />
      <header>
        <img src="../../assets/image/logo.png" alt srcset />
      </header>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in bannerList" :key="index" @click="bannerJump(item.url)">
          <img :src="item.image" alt />
        </van-swipe-item>
      </van-swipe>
      <div class="button-group">
        <div class="convention button-bg2" @click="Jump(7)">
          <img class="icon" src="../../assets/image/register.png" alt srcset />
          <span>大会注册</span>
        </div>
        <div class="convention button-bg2" @click="Jump(6)">
          <img class="icon" src="../../assets/image/synopsis.png" alt srcset />
          <span>大会简介</span>
        </div>
      </div>
      <van-grid class="grid" :column-num="3" :gutter="5">
        <van-grid-item
          @click="Jump(index)"
          class="gird-item"
          v-for="(item, index) in list"
          :key="index"
        >
          <img class="icons" :src="item.url" alt srcset />
          <div class="title">{{ item.name }}</div>
        </van-grid-item>
      </van-grid>
      <div class="sponsor">
        <div class="title">主办单位</div>
        <div class="sponsor-item">
          <div class v-for="(item, index) in sponsorList" :key="index">
            <img class="sponsor-logo" :src="item.image" alt srcset />
          </div>
        </div>
      </div>
    <div class="footer-fill"></div>
    <el-footer :index="1"/>
    </div>
</template>

<script>
import footer from "../../components/footer/footer-tempate.vue";
import {getBanner} from "../../api/index.js"
export default {
  components: {
    "el-footer": footer
  },
  name: "index",
  data() {
    return {
      list: [
        {
          name: "大会日程",
          url: require("../../assets/image/schedule.png")
        },
        {
          name: "讲者介绍",
          url: require("../../assets/image/speaker.png")
        },
        {
          name: "酒店预定周边",
          url: require("../../assets/image/periphery.png")
        },
        {
          name: "精彩瞬间",
          url: require("../../assets/image/instantaneous.png")
        },
        {
          name: "历届峰会回顾",
          url: require("../../assets/image/review.png")
        },
        {
          name: "支持单位",
          url: require("../../assets/image/bulb.png")
        }
      ],
      bannerList: [
        {
          image: require("../../assets/image/banner1.png"),
          url:"http://www.cord.org.cn/intro/42.html"
        },
        {
          image: require("../../assets/image/newbanner2.png"),
          url:"http://goldensnail.raredisease.cn"
        },
        {
          image: require("../../assets/image/newbanner3.png"),
          url:"https://donate.bangbangwang.cn/p/ca2k7n48lw9g1dpwqxmeg0vqpmoxzr3y?utm_bccid=ca1gk45nrwzxl30vo4g2wdvp7e8q29om"
        },
      ],
      sponsorList: [
        {
          image: require("../../assets/image/sponsor1.png"),
          url:"http://www.cord.org.cn/intro/42.html"
        },
        {
          image: require("../../assets/image/sponsor2.png"),
          url:"http://goldensnail.raredisease.cn"
        },
        {
          image: require("../../assets/image/sponsor3.png"),
          url:"https://donate.bangbangwang.cn/p/ca2k7n48lw9g1dpwqxmeg0vqpmoxzr3y?utm_bccid=ca1gk45nrwzxl30vo4g2wdvp7e8q29om"
        },
      ],
    };
  },
  methods: {
    Jump(index) {
      switch (index) {
        case 0:
          this.$router.push("/schedule");
          break;
        case 1:
          this.$router.push("/guest");
          break;
        case 2:
          this.$router.push("/hotel");
          break;
        case 3:
          window.location.href = "https://www.uphoto.cn/m/album/main_ig2766727.html";
          break;
        case 4:
          this.$router.push("/summit");
          break;
        case 5:
          this.$router.push("/qr");
          break;
        case 6:
          this.$router.push("/plenary");
          break;
        case 7:
          window.location.href = "http://lxi.me/yz3yb";
          break;
      }
      console.log(index);
    },
    bannerJump(url){
      window.location.href=url;
    },
    getBanner(){
      getBanner().then(res=>{
        this.bannerList=res.data;
      }).catch(err=>{
        console.log(res)
      })
    }
  },
  created: function(){
    this.getBanner();
  }
};
</script>

<style lang="less" scoped>
.home {
  min-height: 100vh;
  // background-image: url("../../assets/image/background.png");
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  .background {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 100% 100%;
    z-index: -1;
  }
  header {
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    img {
      max-width: 100%;
    }
  }
  .my-swipe {
    width: 92%;
    margin: auto;
    .van-swipe-item {
      color: #fff;
      font-size: 20px;
      // line-height: 150px;
      text-align: center;
      // width: 100%;
      height:150px;
      background-color: #fff;
      img {
        // width: 100%;
        // height: auto;
        // max-width: 100%;
        // width: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .button-group {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 25px auto 0 auto;
    .convention {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 50px;
      border: none;
      color: #fff;
      font-size: 15px;
      .icon {
        margin: 9px 0;
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    .button-bg1 {
      background-image: url("../../assets/image/buttonbg1.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .button-bg2 {
      background-image: url("../../assets/image/buttonbg2.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
  .grid {
    margin: 25px auto;
    width: 90%;
  }
  /deep/ .van-grid-item__content {
    background-color: transparent;
    background-image: url("../../assets/image/gridbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 10px 0;
    height: 100px;
    border: none;
    .icons {
      max-width: 100%;
      height: 32px;
    }
    .title {
      padding-top: 15px;
      font-size: 12px;
      color: #fff;
    }
  }
  /deep/ [class*="van-hairline"]::after {
    position: absolute;
    box-sizing: border-box;
    content: " ";
    pointer-events: none;
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
    border: 0 solid transparent;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  .sponsor {
    padding-bottom: 10px;
    .title {
      margin-top: 20px;
      text-align: center;
      color: #fff;
      font-size: 16px;
    }
    .sponsor-item {
      display: flex;
      justify-content: space-around;
      margin-top: 14px;
      div {
        // background-color: #f0906d;
        width: 30%;
        box-sizing: border-box;
        // padding: 13px 10px 0 10px;
        border-radius: 5px;
        .sponsor-logo {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}
.footer-fill{
  height: 37px;
}
</style>